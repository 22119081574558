import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import apache from '../../../static/apache.png'
import blaze from '../../../static/blaze.png'
import smartbear from '../../../static/smartbear.png'
import microfocus from '../../../static/microfocus.png'

const TheDifferences = () => {
	return (
		<Container fluid className="mt-5" style={{ background: 'rgb(247, 246, 249)' }}>
			<Container className="carousel-columns pt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">OUR EXPERTISE</h6>
					</div>
				</div>
				<h2>WITH THE LEADING PERFORMANCE TESTING TOOLS & TECHNOLOGIES</h2>
				<Row>
					<Col>
						<p className="initial-paragrapgh">
							Our team works with industry-leading solution providers and have hands-on experience with best-in-class industry recognized tools and solutions. We also have a repository of reusable assets to reduce time to market along with ability to setup an on-demand performance lab.
						</p>
					</Col>
				</Row>

			</Container>
			<Container>
				<Row className="pb-5 mt-5">
					<Col>
						<img src={apache} alt="Not Found" width="100%" />
					</Col>
					<Col>
						<img src={blaze} alt="Not Found" width="100%" />
					</Col>
					<Col>
						<img src={smartbear} alt="Not Found" width="100%" />
					</Col>
					<Col>
						<img src={microfocus} alt="Not Found" width="100%" />
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default TheDifferences;