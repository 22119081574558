import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const TheOfferings = () => {
	return (
		<>
			<Container className="carousel-columns mt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">Our Offering</h6>
					</div>
				</div>
				<h2>END-TO-END PERFORMANCE TESTING SERVICES</h2>
			</Container>
			<Container>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							We provide full-cycle performance testing services specifically designed to manage and mitigate the performance risks involved with mission-critical digital applications.
						</p>
						<p className="initial-paragrapgh">
							With GTEN, you’ll not have production bottlenecks that may impact your users.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<div className="initial-paragrapgh">
							Our Services and tool expertise include:
						</div>
						<ul>
								<li className="text-list">Capacity Planning.</li>
								<li className="text-list">Tools Evaluations & Recommendation.</li>
								<li className="text-list">Baseline Testing.</li>
								<li className="text-list">Load, Stress & Endurance Testing.</li>
								<li className="text-list">Application Performance Tunning.</li>
								<li className="text-list">Competitor Benchmarking.</li>
								<li className="text-list">Chaos Engineering.</li>
								<li className="text-list">Production Monitoring.</li>
								<li className="text-list">Performance Improvement Recommendations & Implementation.</li>
							</ul>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default TheOfferings;