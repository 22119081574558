import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheChallenges = () => {
	return (
		<Container fluid>
			<Container className="carousel-columns">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Challenges</h6>
					</div>
				</div>
				<h2>USER EXPECTATIONS ARE HIGHER THAN EVER</h2>
			</Container>
			<Container className="pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							In today’s digital world where users want everything at fingertip, a failure to meet user performance expectations can have a negative impact on a business – quickly and significantly.
							<br />
							Consequences could be loss of customer loyalty, repeat business impacting your business revenue and profit.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<div className="initial-divaragrapgh">
							Enterprises usually find it challenging to:
						</div>
						<ul>
								<li className="text-list">Establish the exact non-functional requirements for improving the performance.</li>
								<li className="text-list">Simulate real-time scenarios with dynamic user load modelling.</li>
								<li className="text-list">With wider adoption of Cloud and auto-scaling another challenge is to identify how to test such an infrastructure.</li>
								<li className="text-list">Establish right metrics and dashboards for performance monitoring and take right decisions quickly.</li>
								<li className="text-list">Identify best fit performance test strategy and tools to support the objectives.</li>
							</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	)
}

export default TheChallenges