import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PerformanceTestEnggContainer from '../containers/PerformanceTestEnggContainer'

const PerformanceTestEngineering = (props) => (
	<Layout
		pageInfo={{ pageName: "Performance Test Engineering" }}
		styleClass="home-page "
		location={props.location.pathname}>
		<SEO title="Performance Test Engineering" />
		<PerformanceTestEnggContainer />
	</Layout>
)

export default PerformanceTestEngineering
