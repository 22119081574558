import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import ServicesNewComponent from '../components/services/ServicesNewComponent'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import FirstComponent from '../components/performancetestengineering/FirstComponent'
import TheOfferings from '../components/performancetestengineering/TheOfferings'
import TheDifferences from '../components/performancetestengineering/TheDifferences'
import TheChallenges from '../components/performancetestengineering/TheChallenges'

class PerformanceTestEnggContainer extends Component {
	render() {
		const ourSolutionData = [
			[{
				noheading: "Better Monitoring",
				description: "At GTEN, we believe that implementing a Shift-Left/Shift-Right culture enables bettermonitoring and early detection of bottlenecks leading to a seamless, well-managed, and integrated performance."
			},
			{
				noheading: "Decision Making",
				description: "Continuous monitoring of performance during engineering and post-production helps to catch performance issues early enabling better decision making. A shift left-right approach for performance engineering helps in preventing performance degradations and application downtime in production."
			},
			{
				noheading: "GAINS",
				description: "Leverage GTEN’s Performance Engineering CoE, enabled by its unified test automation platform – GAINS, decision-enabling dashboards, acceleration kits for projects & process, a cloud-enabled performance testing lab for testing your cloud, IoT, & mobile-based apps, websites, and more to achieve scalability, improve quality,& increase ROI."
			}]]
		return (
			<>
				<BannerImage
					activeTab="Load Testing and Performance"
					bannerImage={banner}
					text="Your users can leave the application within 4 secs." />
				<FirstComponent />
				<TheChallenges />
				<ServicesNewComponent
					heading="The Solutions"
					height="300px"
					bgColor='#ffffff'
					page="noheading"
					subheading="SHIFT-LEFT, SHIFT-RIGHT, AND PERFORMANCE ENGINEERING APPROACHES ENHANCE END USER EXPERIENCE."
					data={ourSolutionData} />
				<TheOfferings />
				<TheDifferences />
				<CaseStudyComponent />
				<TestimonialComponent />
			</>
		)
	}
}

export default PerformanceTestEnggContainer