import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const FirstComponent = () => {
	return (
		<>
			<Container className="pt-3 pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							Load time and app performance are crucial elements of your product’s success today a customer tend to leave an application if it doesn’t respond within few seconds.
						</p>
						<p className="initial-paragrapgh">
							Our team of performance experts help to measure, validate and verify operational capabilities at high loads on cloud native applications to ensure you can offer a flawless customer experience.
						</p>
					</Col>
				</Row>
				<hr/>
			</Container>
		</>
	);
};

export default FirstComponent;